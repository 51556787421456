import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import School from "@material-ui/icons/School";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import LaptopChromebook from "@material-ui/icons/LaptopChromebook";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Embracing Digital</h2>
            <h5 className={classes.description}>
              The use of technology can help to improve communication and collaboration, allow for remote and on-demand access to data and analytics, and even boost employee and customer experience. Digitization and automation can help streamline processes to increase efficiency and improve productivity, ultimately saving time, money, and other valuable resources.
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Learn"
                description="I promote a learning mindset and free thinking by nuturing curiosity and mental stimulation. I study new tools and technologies, and look for ways to apply them in my life and my work."
                icon={School}
                iconColor="primaryAccent"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Build"
                description="I identify opportunities for technological transformation and create digital solutions that deliver lasting value. I design and develop with the user in mind."
                icon={LaptopChromebook}
                iconColor="primaryAccent"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Lead"
                description="I empower those around me to reach their potential by leveraging their strengths and emphasizing their individual importance. I foster clear and proactive communication, and a collaborative environment."
                icon={SupervisorAccount}
                iconColor="primaryAccent"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductSection);
